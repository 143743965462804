.row {
	flex-direction: row;
}

.App {
	background-color: rgb(56 56 56);
	padding-top: 48px;
	padding-bottom: 24px;
}

.App-container {
	background-color: white;
	border-radius: 6px;
}

.logo {
	text-align: center;
	padding-top: 24px;
	font-size: large;
	font-weight: bold;
	color: #868483;
	font-style: italic;
}

.page-top {
	margin-top: 12px;
	margin-bottom: 24px;
}

.calendar-info {
	padding: 24px;
	background-color: #f3f3f3;
	border-radius: 4px;
}

.calendar-title {
	font-size: 2.5em;
	justify-content: center;
}

.calendar-title.result {
	margin-left: -44px;
}

@media screen and (max-width: 530px) {
	.calendar-title.result {
		margin-left: 0;
	}
}

.calendar-title.result .ui.button {
	margin-right: 2px;
}

.calendar-title.editable-text.result:not(.placeholder) {
	font-weight: bolder;
}

.share-box {
	text-align: center;
	margin-bottom: 12px;
}

.share-box .ui.action.input {
	width: 90%;
	max-width: 500px;
}

.share-box .ui.input.mobile-small-only {
	width: 100%;
}

.share-button-container {
	display: flex;
	justify-content: center;
	margin-top: 12px;
}
.share-button-container .ui.button {
	margin: 0;
}

.hours-options {
	flex: 1;
}

.hours-options:not(:first-child) {
	border-left: solid 1px rgba(0, 0, 0, 0.2);
}
.users-list {
	/* background-color: rgb(202, 202, 202);
	padding: 12px; */
}

.editable-text {
	display: flex;
	align-items: center;
	cursor: default;
}

.editable-text.placeholder {
	color: rgb(177, 177, 177);
	font-style: italic;
}

.editable-text.text-area {
	flex-direction: column;
	align-items: flex-start;
}

.editable-text.input .buttons {
	margin-left: 8px;
}

.editable-text.text-area .ui.form {
	margin-bottom: 12px;
	width: 100%;
}

.editable-text.result .ui.button {
	opacity: 0;
	transition: 250ms;
	width: 40px;
}

.editable-text:hover .ui.button {
	opacity: 1;
}

.ui.button.transparent {
	background-color: transparent !important;
}

.info-area {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 32px;
	margin-left: 32px;
}
@media screen and (max-width: 530px) {
	.info-area {
		margin-left: 0;
	}
}

.userinfo {
	flex: 1;
}

.userinfo .editable-text.result {
	flex-direction: row-reverse;
	justify-content: flex-end;
}

.toggle-everyone-container {
	text-align: right;
	margin-bottom: 8px;
}

.date-navigator {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 16px;
	background-color: #dedcff;
}

.date-navigator .title {
	/* font-weight: bold; */
	font-size: large;
}

.week-arrow-container {
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	display: flex;
	align-items: center;
}

.week-arrow-container .ui.button {
	align-items: center;
	justify-content: center;
	height: 58px;
	display: flex;
}

.week-arrow-container .ui.button .icon {
	margin: 0;
}

.hint {
	color: rgb(130 121 121);
	cursor: default;
}

.week .hours-column {
	flex-direction: column;
	flex: 0 0 auto;
	text-align: right;
	padding-right: 8px;
	cursor: default;
	position: relative;
	top: -10px;
}

.week .options {
	flex: 1;
	display: flex;
	flex-direction: row;
	border: solid 1px rgba(0, 0, 0, 0.2);
}

.week .top-bar {
	display: flex;
	flex: row;
}

.week .days {
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: space-around;
	background-color: white;
}

.week .top-bar .hint {
	padding-right: 8px;
	display: flex;
	align-items: center;
	font-weight: bold;
	justify-content: flex-end;
	background-color: white;
}

.week .top-bar .hint,
.week .hours-column {
	width: 100px;
}
@media screen and (max-width: 530px) {
	.week .top-bar .hint,
	.week .hours-column {
		width: 50px;
	}
}

.week .table {
	padding-top: 24px;
	display: flex;
	flex: 1;
	flex-direction: row;
	overflow: auto;
	margin-bottom: 12px;
}

.week .hour-label {
	height: 48px;
}

.clickable-time {
	height: 24px;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;

	display: flex;
	justify-content: center;

	font-weight: bold;

	color: #4d773c;
}

.clickable-time .icon {
	position: relative;
	bottom: 1px;
	opacity: 0.3;
}

.clickable-time:first-child {
	height: 24px;
	border-bottom: dashed 1px rgba(0, 0, 0, 0.2);
}

.clickable-time:last-child {
	height: 24px;
	border-bottom: solid 1px rgba(0, 0, 0, 0.2);
}

.clickable-time:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.clickable-time.has-other-users {
	background-color: rgba(50, 168, 50, var(--percentage, 1));
}

.clickable-time.user-selected {
	height: 24px;
	background-color: rgba(132, 147, 158, var(--percentage, 1));
	/* color: #656565; */
}

.clickable-time.user-selected,
.clickable-time.has-other-users {
	/* border-radius: 6px; */
}

.clickable-time.user-selected.has-other-users {
	background-color: rgba(173, 203, 224, var(--percentage, 1));
	color: #61859e;
}

.ui.popup .block-popup-name {
	font-size: small;
}

.day {
	cursor: default;
}
.day.today {
	color: #2185d0;
	font-weight: bold;
}

.day .name {
	font-size: large;
}

.saving-bar{
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	background-color: rgba(255, 255, 255, 0.705);
	bottom: 0;
	left: 0;
	right: 0;
	height: 50px;
	transition: opacity 250ms;
}

.hidden{
	opacity: 0;
	pointer-events: none;
}

.saving-bar .content{
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
	align-items: center;
	padding-right: 8px;
}

.mobile-large-only {
	display: none !important;
}

.mobile-small-only {
	display: none !important;
}

@media screen and (max-width: 993px) and (min-width: 530px) {
	.desktop-only {
		display: none !important;
	}
	.mobile-large-only {
		display: initial !important;
	}
	.mobile-small-only {
		display: none !important;
	}
}

@media screen and (max-width: 530px) {
	.desktop-only {
		display: none !important;
	}
	.mobile-large-only {
		display: none !important;
	}
	.mobile-small-only {
		display: initial !important;
	}
	.not-mobile-small-only {
		display: none !important;
	}
}


